import React, { useEffect, useState } from "react";
import { Grid, Button, Typography, Divider } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useMediaQuery } from "react-responsive";
import Checkbox from "@material-ui/core/Checkbox";
import PaymentMethod from "./PaymentMethod";
import Radio from "@material-ui/core/Radio";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useForm, Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import { makeStyles } from "@material-ui/core/styles";
import "react-phone-input-2/lib/bootstrap.css";
import MyPhoneInput from "../PhoneInput/PhoneInput";
import { axiosInstance } from "../../axios";
import { Spin } from "antd";
import { useNavigate, useParams } from "react-router-dom";

const GreenCheckbox = withStyles({
  root: {
    color: "#4D4D4D",
    "&$checked": {
      color: "#4D4D4D",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

function DonationCampaign(props) {
  const { register, handleSubmit, control, errors, setValue } = useForm();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadPaymentChannel, setIsLoadPaymentChannel] = useState(false);
  const { id } = useParams();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [doa, setDoa] = useState("");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [availablePayments, setAvailablePayments] = useState();
  const [isHambaAllah, setIsHambaAllah] = useState(false);
  const [cardDisplay, setCardDisplay] = useState("");
  const [customPrice, setCustomPrice] = useState(0);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [inputPhoneValue, setInputPhoneValue] = useState();
  const [first, setFirst] = useState(true);
  const [campaign, setCampaign] = useState(null);
  const [firstLoading, setFirstLoading] = useState(true);

  const getCampaign = async () => {
    const fetchData = await axiosInstance.get(
      `/customer/ecommerce/products/${id}`
    );

    setCampaign(fetchData.data.data);
    setFirstLoading(false);
  };

  useEffect(() => {
    setFirstLoading(true);
    const data = JSON.parse(localStorage.getItem("savedUser"));
    if (data) {
      setName(data.name);
      setEmail(data.email);
      setDoa(data.doa);
      setInputPhoneValue(data.phone);
      setValue("phone", data.phone, { shouldValidate: false });
      setValue("name", data.phone, { shouldValidate: false });
      setValue("email", data.email, { shouldValidate: false });
    }
    if (id) {
      getCampaign();
    }
  }, [id]);

  useEffect(() => {
    let data = {
      name: name,
      phone: inputPhoneValue,
      email: email,
    };
    localStorage.setItem("savedUser", JSON.stringify(data));
  }, [name, inputPhoneValue, email]);

  const handleIsHambaAllah = (event) => {
    setIsHambaAllah(event.target.checked);
  };

  const calculateDonation = async (nominal) => {
    const price = nominal ? nominal : customPrice;

    if (price >= 1000) {
      setIsLoadPaymentChannel(true);

      const res = await props.handleCalculate(price, campaign);

      if (res) {
        setIsLoadPaymentChannel(false);
        setAvailablePayments(res.data.data.availablePayments);
        if (selectedPaymentMethod) {
          const isAvailablePayment = res.data.data.availablePayments.find(
            (item) => item.id === selectedPaymentMethod?.id
          );
          if (
            !isAvailablePayment?.minimumTransaction ||
            Number(price) < isAvailablePayment?.minimumTransaction
          ) {
            setValue("selectedPaymentId", null, {
              shouldValidate: true,
            });
            setSelectedPaymentMethod(null);
          }
        }
      } else {
        setIsLoadPaymentChannel(false);
      }
    }
  };

  const onSubmit = async (data) => {
    const phoneNumber =
      inputPhoneValue.phone.slice(0, 2) === "08"
        ? inputPhoneValue.code.toString() +
          inputPhoneValue.phone.slice(1, inputPhoneValue.phone.length)
        : inputPhoneValue.phone.slice(0, 2) === "62"
        ? inputPhoneValue.phone
        : inputPhoneValue.code.toString() + inputPhoneValue.phone;

    const dataDonation = {
      ...(campaign?.id === process.env.ZAKAT_ID
        ? {
            items: [
              {
                productId: campaign?.id,
                quantity: 1,
                customPrice: Number(customPrice),
              },
            ],
          }
        : {
            item: {
              productId: campaign?.id,
              quantity: 1,
              customPrice: Number(customPrice),
            },
          }),

      contact: {
        phone: phoneNumber,
        email: data.email,
      },
      shipping: null,
      billing: {
        name: data.name,
        phone: phoneNumber,
        email: data.email,
        address: "",
        postcode: "",
        country: "",
        latitude: null,
        longitude: null,
        note: "",
        province: "",
        cityId: "",
        city: "",
      },
      voucherCode: "",
      customerNote: data.doa,
      paymentChannelId: data.selectedPaymentId,
      isAnonymousDonor: isHambaAllah,
    };

    if (customPrice >= 1000) {
      setIsLoading(true);

      const res = await props.handleSubmit(dataDonation);

      if (res) {
        window.location.href = res.data.data.payments[0].url;
      } else {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (!first) {
      setValue("nominal", customPrice, { shouldValidate: true });
      setValue("phone", inputPhoneValue, { shouldValidate: true });
      setValue("name", name, { shouldValidate: true });
      setValue("email", email, { shouldValidate: true });
      setValue("selectedPaymentId", selectedPaymentMethod?.id, {
        shouldValidate: true,
      });
    }
    setFirst(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [cardDisplay]);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (customPrice) {
        calculateDonation();
      }
    }, 500);
    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, [customPrice]);

  if (cardDisplay === "donationMethod") {
    return (
      <PaymentMethod
        availablePayments={availablePayments}
        handleSelectedPayment={(value) => setSelectedPaymentMethod(value)}
        setDisplayCard={() => setCardDisplay("")}
      />
    );
  }

  return (
    <>
      <div
        style={{
          backgroundColor: "rgba(0,0,0,0.5)",
          width: "100%",
          height: "100%",
          position: "fixed",
          right: "0",
          top: "0",
          zIndex: "50",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          display: `${isLoading ? "flex" : "none"}`,
        }}
      >
        <CircularProgress style={{ color: "#ffffff" }} />
      </div>
      <Spin spinning={firstLoading}>
        <form
          id="form-donation"
          onSubmit={handleSubmit(onSubmit)}
          style={{ padding: "0px 25px", paddingTop: 18, paddingBottom: 10 }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={campaign?.image?.url}
              alt={campaign?.name}
              style={{ width: 130, height: 130, borderRadius: 10 }}
            />
            <div style={{ marginLeft: 16 }}>
              <div style={{ fontWeight: 600, fontSize: 18 }}>
                {campaign?.name}
              </div>
              <div
                style={{
                  fontSize: 14,
                  fontWeight: 600,
                  color: "#666666",
                  margin: "8px 0px",
                }}
              >
                Kamu akan berdonasi untuk
              </div>
              <div
                style={{
                  fontWeight: 400,
                  fontSize: 12,
                  color: "#057A8E",
                  textDecoration: "underline",
                }}
                onClick={() => navigate(`/campaigns/${campaign?.slug}`)}
              >
                {campaign?.name}
              </div>
            </div>
          </div>

          <Divider style={{ margin: "8px 0px 16px" }} />

          <div
            style={{
              display: "grid",
              gridGap: 10,
              gridTemplateColumns: "auto auto auto",
            }}
          >
            <Button
              onClick={() => {
                setCustomPrice(5000);
                calculateDonation(5000);
                setValue("nominal", 5000, { shouldValidate: true });
              }}
              style={{
                padding: "3px 0px",
                border: "1px solid #CCCCCC",
                borderRadius: 2,
                width: "100%",
                textTransform: "inherit",
                display: "inherit",
                alignItems: "none",
                justifyContent: "none",
              }}
            >
              <Typography
                item
                xs={6}
                style={{
                  fontWeight: 700,
                  fontSize: 14,
                  color: "#4D4D4D",
                }}
              >
                Rp 5.000
              </Typography>
            </Button>

            <Button
              onClick={() => {
                setCustomPrice(10000);
                calculateDonation(10000);
                setValue("nominal", 10000, { shouldValidate: true });
              }}
              style={{
                padding: "3px 0px",
                border: "1px solid #CCCCCC",
                borderRadius: 2,
                width: "100%",
                textTransform: "inherit",
                display: "inherit",
                alignItems: "none",
                justifyContent: "none",
              }}
            >
              <Typography
                item
                xs={6}
                style={{
                  fontWeight: 700,
                  fontSize: 14,
                  color: "#4D4D4D",
                }}
              >
                Rp 10.000
              </Typography>
            </Button>

            <Button
              onClick={() => {
                setCustomPrice(25000);
                calculateDonation(25000);
                setValue("nominal", 25000, { shouldValidate: true });
              }}
              style={{
                padding: "3px 0px",
                border: "1px solid #CCCCCC",
                borderRadius: 2,
                width: "100%",
                textTransform: "inherit",
                display: "inherit",
                alignItems: "none",
                justifyContent: "none",
              }}
            >
              <Typography
                item
                xs={6}
                style={{
                  fontWeight: 700,
                  fontSize: 14,
                  color: "#4D4D4D",
                }}
              >
                Rp 25.000
              </Typography>
            </Button>

            <Button
              onClick={() => {
                setCustomPrice(50000);
                calculateDonation(50000);
                setValue("nominal", 50000, { shouldValidate: true });
              }}
              style={{
                padding: "3px 0px",
                border: "1px solid #CCCCCC",
                borderRadius: 2,
                width: "100%",
                textTransform: "inherit",
                display: "inherit",
                alignItems: "none",
                justifyContent: "none",
              }}
            >
              <Typography
                item
                xs={6}
                style={{
                  fontWeight: 700,
                  fontSize: 14,
                  color: "#4D4D4D",
                }}
              >
                Rp 50.000
              </Typography>
            </Button>
            <Button
              onClick={() => {
                setCustomPrice(100000);
                calculateDonation(100000);
                setValue("nominal", 100000, { shouldValidate: true });
              }}
              style={{
                padding: "3px 0px",
                border: "1px solid #CCCCCC",
                borderRadius: 2,
                width: "100%",
                textTransform: "inherit",
                display: "inherit",
                alignItems: "none",
                justifyContent: "none",
              }}
            >
              <Typography
                item
                xs={6}
                style={{
                  fontWeight: 700,
                  fontSize: 14,
                  color: "#4D4D4D",
                }}
              >
                Rp 100.000
              </Typography>
            </Button>
            <Button
              onClick={() => {
                setCustomPrice(500000);
                calculateDonation(500000);
                setValue("nominal", 500000, { shouldValidate: true });
              }}
              style={{
                padding: "3px 0px",
                border: "1px solid #CCCCCC",
                borderRadius: 2,
                width: "100%",
                textTransform: "inherit",
                display: "inherit",
                alignItems: "none",
                justifyContent: "none",
              }}
            >
              <Typography
                item
                xs={6}
                style={{
                  fontWeight: 700,
                  fontSize: 14,
                  color: "#4D4D4D",
                }}
              >
                Rp 500.000
              </Typography>
            </Button>
          </div>

          <Grid
            style={{
              padding: "8px 16px",
              border: "1px solid #CCCCCC",
              borderRadius: 2,
              marginTop: 12,
            }}
          >
            <Grid style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                item
                xs={6}
                style={{
                  fontWeight: 700,
                  fontSize: 12,
                  color: "#4D4D4D",
                }}
              >
                Nominal donasi
              </Typography>
            </Grid>

            <div
              style={{
                display: "flex",
                backgroundColor: "#F5F5F5",
                padding: "7px 11px",
                borderRadius: 2,
                marginTop: 6,
                justifyContent: "space-between",
              }}
            >
              <Typography
                style={{
                  fontWeight: 700,
                  fontSize: 16,
                  color: "#4D4D4D",
                  backgroundColor: "#F5F5F5",
                }}
              >
                Rp
              </Typography>
              <Controller
                control={control}
                defaultValue=""
                name="nominal"
                rules={{
                  required: true,
                  min:
                    campaign?.customPriceConfig?.minimumCustomPrice > 1000
                      ? campaign?.customPriceConfig?.minimumCustomPrice
                      : 1000,
                }}
                render={({ onChange, onBlur }) => (
                  <NumberFormat
                    thousandSeparator={true}
                    style={{
                      border: "none",
                      textAlign: "right",
                      backgroundColor: "inherit",
                      fontWeight: "700",
                    }}
                    value={customPrice}
                    onValueChange={(values) => {
                      const { value } = values;
                      setCustomPrice(value);
                    }}
                    onChange={(e) => {
                      onChange(e);
                    }}
                    onBlur={(e) => {
                      onBlur(e);
                    }}
                  />
                )}
              />
            </div>
            {errors.nominal?.type === "required" && (
              <div style={{ color: "red" }}>nominal harus diisi!</div>
            )}
            {errors.nominal?.type === "min" && (
              <div style={{ color: "red" }}>
                Nominal minimal Rp.
                {campaign?.customPriceConfig?.minimumCustomPrice} untuk e-wallet
              </div>
            )}
          </Grid>
          <Typography
            style={{
              fontWeight: 700,
              fontSize: 12,
              color: 666666,
              marginTop: 12,
            }}
          >
            Metode Pembayaran
          </Typography>
          <Button
            disabled={!availablePayments}
            onClick={() => setCardDisplay("donationMethod")}
            style={{
              display: "flex",
              padding: 0,
              paddingTop: 5,
              paddingBottom: 18,
              cursor: "pointer",
              width: "100%",
            }}
          >
            <Grid item xs={9} style={{ alignItems: "center", display: "flex" }}>
              {selectedPaymentMethod && !isLoadPaymentChannel && (
                <img
                  style={{ width: "3rem" }}
                  src={selectedPaymentMethod?.imageUrl}
                  height="inherit"
                />
              )}
              {isLoadPaymentChannel ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CircularProgress
                    style={{
                      color: "#2DBE78",
                      width: "20px",
                      height: "inherit",
                    }}
                  />
                  <span
                    style={{
                      marginLeft: 5,
                      textTransform: "initial",
                      // whiteSpace: "nowrap",
                      fontSize: "0.7rem",
                      color: "black",
                      textAlign: "left",
                    }}
                  >
                    Sedang mengambil metode pembayaran...
                  </span>
                </div>
              ) : availablePayments ? (
                <Typography
                  style={{
                    marginLeft: "0.5rem",
                    fontSize: 14,
                    fontWeight: 400,
                    textTransform: "capitalize",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {!selectedPaymentMethod
                    ? "Pilih metode pembayaran"
                    : selectedPaymentMethod?.name}
                </Typography>
              ) : (
                <Typography
                  style={{
                    marginLeft: "1rem",
                    fontSize: 14,
                    fontWeight: 400,
                    textTransform: "capitalize",
                    whiteSpace: "nowrap",
                  }}
                >
                  Masukkan nominal terlebih dahulu!
                </Typography>
              )}
            </Grid>
            <Grid
              item
              xs={3}
              style={{ textAlign: "right", alignSelf: "center" }}
              onClick={() => setCardDisplay("donationMethod")}
            >
              {selectedPaymentMethod ? (
                <Button
                  style={{
                    padding: "2px 12px",
                    background: "#35CF85",
                    borderRadius: 20,
                  }}
                >
                  <Typography
                    style={{
                      fontSize: 12,
                      fontWeight: 600,
                      color: "rgba(250, 250, 250, 1)",
                      textTransform: "capitalize",
                    }}
                  >
                    Ganti
                  </Typography>
                  <KeyboardArrowDownRoundedIcon
                    style={{
                      color: "rgba(250, 250, 250, 1)",
                      marginLeft: 3,
                    }}
                  />
                </Button>
              ) : (
                <ChevronRightRoundedIcon
                  style={{ color: "#CCCCCC", verticalAlign: "top" }}
                />
              )}
            </Grid>
          </Button>
          <input
            ref={register({ required: true })}
            name="selectedPaymentId"
            type="hidden"
            value={selectedPaymentMethod ? selectedPaymentMethod?.id : ""}
          />
          {errors.selectedPaymentId && (
            <p style={{ color: "red" }}>Silahkan pilih metode pembayarannya</p>
          )}

          <div
            style={{
              fontWeight: 700,
              fontSize: 12,
              color: 666666,
              margin: "12px 0px 3px 0px",
            }}
          >
            Lengkapi Data
          </div>
          <Controller
            control={control}
            defaultValue=""
            name="name"
            rules={{ required: true, minLength: 3 }}
            render={({ onChange }) => (
              <input
                value={name}
                type="text"
                onChange={(e) => {
                  onChange(e);
                  setName(e.target.value);
                  setValue("name", e.target.value, {
                    shouldValidate: true,
                  });
                }}
                style={{
                  padding: "14px 12px",
                  fontSize: 14,
                  fontWeight: 400,
                  backgroundColor: "#F5F5F5",
                  border: "1px solid #CCCCCC",
                  outline: "none",
                  width: "100%",
                  textAlign: "start",
                  borderRadius: 2,
                  marginTop: 14,
                }}
                placeholder={"Masukkan Nama Lengkap"}
              />
            )}
          />

          {errors.name?.type === "required" && (
            <span style={{ color: "red" }}>
              Nama mohon diisi. Bisa tidak ditampilkan
            </span>
          )}
          {errors.name?.type === "minLength" && (
            <span style={{ color: "red" }}>
              Nama harus lebih dari 3 karakter
            </span>
          )}

          <Controller
            control={control}
            defaultValue=""
            name="phone"
            countryCodeEditable={false}
            rules={{ required: true, minLength: 8 }}
            render={({ onChange }) => (
              <MyPhoneInput
                value={inputPhoneValue && inputPhoneValue.phone}
                values={inputPhoneValue}
                style={{ marginTop: 12, backgroundColor: "#f5f5f5" }}
                placeholder="Enter phone number"
                onChange={(v) => {
                  onChange(v);
                  setValue("phone", v, { shouldValidate: true });
                  setInputPhoneValue(v);
                }}
              />
            )}
          />

          {errors.phone?.type === "required" && (
            <span style={{ color: "red" }}>
              Nomor ponsel mohon diisi. Tidak akan ditampilkan
            </span>
          )}
          {inputPhoneValue && inputPhoneValue?.phone?.length < 6 && (
            <span style={{ color: "red" }}>
              Nomor ponsel harus lebih dari 6 karakter
            </span>
          )}

          <div
            style={{
              fontWeight: 700,
              fontSize: 12,
              color: 666666,
              margin: "12px 0px 3px 0px",
            }}
          >
            Email (Opsional)
          </div>
          <Controller
            control={control}
            defaultValue=""
            name="email"
            rules={{
              // required: true,
              pattern: {
                value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                message: "invalid email address",
              },
            }}
            render={({ onChange }) => (
              <input
                value={email}
                type="text"
                onChange={(e) => {
                  onChange(e);
                  setEmail(e.target.value);
                  setValue("email", e.target.value, {
                    shouldValidate: true,
                  });
                }}
                style={{
                  padding: "14px 12px",
                  fontSize: 14,
                  fontWeight: 400,
                  backgroundColor: "#F5F5F5",
                  border: "1px solid #CCCCCC",
                  outline: "none",
                  width: "100%",
                  textAlign: "start",
                  borderRadius: 2,
                  marginTop: 14,
                }}
                placeholder={"Masukkan Email (Opsional)"}
              />
            )}
          />

          {email && errors?.email?.message && (
            <span style={{ color: "red" }}>Mohon input email yang valid!</span>
          )}

          <div
            style={{
              fontWeight: 700,
              fontSize: 12,
              color: 666666,
              margin: "12px 0px 3px 0px",
            }}
          >
            Dukungan dan Doa (Opsional)
          </div>
          <Controller
            control={control}
            defaultValue=""
            name="doa"
            rules={{ required: false }}
            render={({ onChange }) => (
              <input
                value={doa}
                type="text"
                onChange={(e) => {
                  onChange(e);
                  setDoa(e.target.value);
                  setValue("doa", e.target.value, {
                    shouldValidate: true,
                  });
                }}
                style={{
                  padding: "14px 12px",
                  fontSize: 14,
                  fontWeight: 400,
                  backgroundColor: "#F5F5F5",
                  border: "1px solid #CCCCCC",
                  outline: "none",
                  width: "100%",
                  textAlign: "start",
                  borderRadius: 2,
                  marginTop: 14,
                }}
                placeholder={"Dukungan dan Doa (Opsional)"}
              />
            )}
          />

          <Grid
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              marginTop: 14,
            }}
          >
            <FormControlLabel
              style={{ margin: 0 }}
              control={
                <GreenCheckbox
                  checked={isHambaAllah}
                  onChange={handleIsHambaAllah}
                  name="isHambaAllah"
                  ref={register}
                  fontSize="small"
                  style={{ padding: 0 }}
                />
              }
            />
            <Typography
              style={{
                fontSize: 11,
                fontWeight: 700,
                color: "#4D4D4D",
                marginLeft: 8,
              }}
            >
              Donasi sebagai Hamba Allah
            </Typography>
          </Grid>
          <Button
            type="submit"
            form="form-donation"
            disabled={!isValidPhone || !selectedPaymentMethod}
            style={{
              backgroundColor:
                customPrice < 1 || !selectedPaymentMethod
                  ? "#B8B8B8"
                  : "#2DBE78",
              marginTop: 22,
              marginBottom: 16,
              width: "100%",
              color: "#FAFAFA",
              padding: 12,
              textTransform: "inherit",
              fontWeight: 600,
            }}
          >
            Bismillah Donasi Sekarang
          </Button>
          <Typography
            style={{
              fontSize: 10,
              fontWeight: 400,
              color: "rgb(109 109 109)",
              marginBottom: 10,
            }}
          >
            Dana yang didonasikan bukan bersumber dan
            bukan untuk tujuan sesuatu yang dilarang Allah seperti terorisme,
            korupsi, pencucian uang (money laundry), dll.
          </Typography>
        </form>
      </Spin>
    </>
  );
}

export async function getServerSideProps(context) {
  const fetchData = await axiosInstance.get(
    `/customer/ecommerce/products/${context.query.slug}`
  );

  return {
    props: {
      apiResponse: fetchData.data.data,
    },
  };
}

export default DonationCampaign;
