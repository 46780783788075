import React from "react";
import { makeStyles, LinearProgress, CardMedia } from "@material-ui/core";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles({
  cardCampaign: {
    border: "1px solid #E2E2E2",
    backgroundColor: "#FFFFFF",
    borderRadius: 10,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  image: {
    height: 200,
    width: "100%",
    borderRadius: "10px 10px 0 0",
    objectFit: "cover",
  },
  cardMedia: {
    height: 40,
    padding: "12px 10px",
    backgroundColor: "#2DBE78",
    fontSize: 14,
    fontWeight: 600,
    color: "#ffffff",
    borderRadius: 5,
  },
  activeBar: {
    backgroundColor: "#2DBE78",
  },
  buttonCard: {
    borderRadius: 5,
    padding: "12px 0px",
    textAlign: "center",
    fontSize: 14,
    fontWeight: 600,
    color: "#ffffff",
    cursor: "pointer",
  },
});

const Campaign = ({ data }) => {
  let navigate = useNavigate();
  const classes = useStyles();
  const formatedPrice =
    "Rp " + data.totalSales?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  const currentDate = new Date();
  const expiredDate = new Date(data.expiredAt);

  const DifferenceInTime = expiredDate.getTime() - currentDate.getTime();

  const DifferenceInDays = Math.ceil(DifferenceInTime / (1000 * 3600 * 24));

  return (
    <div
      className={classes.cardCampaign}
      onClick={() => navigate(`/campaigns/${data.slug}`)}
    >
      <CardMedia image={data.image.url} className={classes.image}>
        <div
          style={{
            paddingTop: 13,
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: 10,
          }}
        >
          <div className={classes.cardMedia}>
            {data?.categories?.[0]?.name || "-"}
          </div>
        </div>
      </CardMedia>
      <div style={{ padding: 16 }}>
        <div
          style={{
            fontSize: 18,
            fontWeight: 600,
            color: "#1A1A1A",
            lineHeight: "30px",
            marginBottom: 8,
            wordBreak: "break-word",
          }}
        >
          {data.name}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "14px 0px 22px",
          }}
        >
          <div style={{ width: "55%" }}>
            <div
              style={{
                fontSize: 10,
                fontWeigth: 600,
                color: "#808080",
                marginBottom: 8,
              }}
            >
              Terkumpul
            </div>
            <div
              style={{
                color: "#2DBE78",
                fontWeight: 600,
                fontSize: 14,
              }}
            >
              {formatedPrice}{" "}
              {data?.target && (
                <span
                  style={{ fontSize: 14, fontWeight: 600, color: "#808080" }}
                >
                  ({((data?.totalSales / data?.target) * 100).toFixed(2)}%)
                </span>
              )}
            </div>
          </div>

          <div>
            {data?.target && (
              <>
                <div
                  style={{
                    fontSize: 10,
                    fontWeigth: 600,
                    color: "#808080",
                    marginBottom: 8,
                    textAlign: "right",
                  }}
                >
                  Dana Dibutuhkan
                </div>

                <div
                  style={{
                    fontWeight: 14,
                    color: "#808080",
                    fontWeight: 700,
                    marginTop: 10,
                  }}
                >
                  Rp{" "}
                  {data?.target
                    ?.toString()
                    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                </div>
              </>
            )}

            {/* <div
                style={{
                  fontSize: 14,
                  fontWeight: 600,
                  color: "#808080",
                }}
              >
                {} Hari Lagi
              </div> */}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "14px 0px 22px",
          }}
        >
          <div style={{ width: "55%" }}>
            <div
              style={{
                fontSize: 10,
                fontWeigth: 600,
                color: "#808080",
                marginBottom: 8,
              }}
            >
              Tersalurkan
            </div>
            <div
              style={{
                color: "#2DBE78",
                fontWeight: 600,
                fontSize: 14,
              }}
            >
              Rp{" "}
              {data?.totalExpense
                ?.toString()
                ?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                {" "}
                <span
                  style={{
                    fontSize: 14,
                    fontWeight: 600,
                    color: "#808080",
                  }}
                > 
                  ({((data?.totalExpense / data?.totalSales) * 100).toFixed(2)}%)
                </span>

            </div>
          </div>

          <div>
            <div
              style={{
                fontSize: 10,
                fontWeigth: 600,
                color: "#808080",
                marginBottom: 8,
                textAlign: "right",
              }}
            >
              Sisa Saldo
            </div>

            <div
              style={{
                fontWeight: 14,
                color: "#808080",
                fontWeight: 700,
                marginTop: 10,
              }}
            >
              Rp{" "}
              {data?.totalBalance
                ?.toString()
                ?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
            </div>
          </div>
        </div>
        <LinearProgress
          variant="determinate"
          value={data?.target ? (data.totalSales / data.target) * 100 : 100}
          classes={{
            barColorPrimary: classes.activeBar,
          }}
          style={{ backgroundColor: "#c4c4c4" }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "14px 0px 22px",
          }}
        >
          <div style={{ width: "55%" }}>
            <div
              style={{
                color: "#808080",
                fontWeight: 600,
                fontSize: 14,
              }}
            >
              {data?.totalOrders || "0"} Donatur
            </div>
          </div>

          <div>
            {data?.expiredAt === null ? (
              <div>&#8734; Hari Lagi</div>
            ) : DifferenceInDays > 0 ? (
              <div
                style={{
                  fontSize: 14,
                  fontWeight: 600,
                  color: "#808080",
                }}
              >
                {DifferenceInDays} Hari Lagi
              </div>
            ) : (
              <div
                style={{
                  fontSize: 14,
                  fontWeight: 600,
                  color: "#808080",
                }}
              >
                Expired
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            backgroundColor:
              DifferenceInDays > 0 || data?.expiredAt === null
                ? "#2DBE78"
                : "grey",
          }}
          className={classes.buttonCard}
        >
          Ikut Donasi
        </div>
      </div>
    </div>
  );
};
export default Campaign;
