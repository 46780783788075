import React, { useCallback, useState, useMemo, useEffect } from "react";
import { Input, Select } from "antd";
import { InputProps } from "antd/lib/input";
import { OptionProps, SelectProps } from "antd/lib/select";
import countries from "./source";

function CountryPhoneInput({ onChange, value, values, selectProps, ...props }) {
  const defaultCountry = useMemo(() => {
    return countries.find((c) => c.short === "ID");
  }, []);

  const [country, setCountry] = useState(defaultCountry);
  const [phone, setPhone] = useState();

  useEffect(() => {
    if (values) {
      if (values.short) {
        setCountry(countries.find((c) => c.short === values.short));
      } else {
        setCountry(countries.find((c) => Number(c.phoneCode) === values.code));
      }
      setPhone(values.phone);
    }
  }, [values]);

  const triggerChange = useCallback(
    (phone, country) => {
      const result = {
        phone,
        code: country && Number(country.phoneCode),
        short: country && country.short,
      };
      onChange?.(result);
    },
    [onChange]
  );

  const handleCountryChange = useCallback(
    (values) => {
      const c = countries.find((c) => c.short === values);
      if (!c) {
        throw new Error(`Country not found: ${values}`);
      }
      setCountry(c);
      triggerChange(phone, c);
    },
    [setCountry, triggerChange, phone]
  );

  const handlePhoneChange = useCallback(
    (e) => {
      const currentValue = e.target.value;
      setPhone(currentValue);
      triggerChange(currentValue, country);
    },
    [setPhone, country, triggerChange]
  );

  return (
    <Input
      type="number"
      style={{ backgroundColor: "#f5f5f5" }}
      prefix={
        <Select
          dropdownClassName="phone-country-code"
          bordered={false}
          dropdownMatchSelectWidth={false}
          {...selectProps}
          optionLabelProp="label"
          value={country && country.short}
          onChange={handleCountryChange}
        >
          {countries.map((item) => {
            const fix = {
              key: item.short,
              value: item.short,
              label: `${item.emoji} +${item.phoneCode}`,
            };
            return (
              <Select.Option {...props.optionProps} {...fix}>
                {`${item.emoji} - ${item.en} - ${item.phoneCode}`}
              </Select.Option>
            );
          })}
        </Select>
      }
      {...props}
      value={values && values.phone}
      onChange={handlePhoneChange}
    />
  );
}

export { CountryPhoneInput };
export default CountryPhoneInput;
