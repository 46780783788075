import * as React from "react";
import { Routes, Route, Link } from "react-router-dom";
import Button from '@mui/material/Button';
import { axiosInstance } from "./axios";
import DonationList from './DonationList';
import DonationDetail from "./DonationDetail";
import SubmitDonation from "./SubmitDonation";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<DonationList />} />
        <Route path="/campaigns" element={<DonationList />} />
        <Route path="/campaigns/:id" element={<DonationDetail />} />
        <Route path="/campaigns/donation/:id" element={<SubmitDonation />} />
        <Route path="about" element={<About />} />
      </Routes>
    </div>
  );
}

// App.js
function Home() {
  const [donations, setDonations] = React.useState([]);
  const getListProducts = async () => {
    const endpoint = "customer/ecommerce/products";
    const { data } = await axiosInstance.get(endpoint);
    setDonations(data);
    console.log(donations)
    return data;
  };

  return (
    <>
      <main>
        <h2>Welcome to the homepage!</h2>
        <p>You can do this, I believe in you.</p>
      </main>
      <nav>
        <Link to="/about">About</Link>
        <Button variant="contained" onClick={getListProducts}>Hello World</Button>
      </nav>
    </>
  );
}

function About() {
  return (
    <>
      <main>
        <h2>Who are we?</h2>
        <p>
          That feels like an existential question, don't you
          think?
        </p>
      </main>
      <nav>
        <Link to="/">Home</Link>
      </nav>
    </>
  );
}

export default App;