import React, { useEffect } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { useMediaQuery } from "react-responsive";
import CardCampaign from "./components/CardCampaign";
import { Spin } from "antd";
import { axiosInstance } from "./axios";

const useStyles = makeStyles({
  title: {
    color: "black",
    fontWeight: 600,
    fontSize: 28,
    textAlign: "center",
  },
  info: {
    fontSize: 16,
    fontWeight: 600,
    color: "#666666",
  },
  searchDiv: {
    height: 45,
    borderRadius: 8,
    backgroundColor: "#FAFAFA",
    padding: "12px 16px",
    display: "flex",
    width: 450,
  },
  searchIcon: {
    marginRight: 10,
  },
  inputRoot: {
    marginLeft: 0,
    color: "inherit",
    width: "100%",
    height: "100%",
  },
  inputInput: {
    width: "100%",
    fontSize: "14px !important",
  },
  searchButton: {
    padding: 12,
    fontSize: 12,
    fontWeight: 700,
    color: "white",
    borderRadius: 8,
    backgroundColor: "#2DBE78",
    width: "fit-content",
    marginLeft: 12,
  },
  page: {
    "&$selected": {
      color: "white",
      backgroundColor: "#2DBE78",
      "&:hover": {
        backgroundColor: "#2DBE78",
      },
    },
  },
  selected: {},
});

function DonationList() {
  const classes = useStyles();
  const xs = useMediaQuery({ maxWidth: 650 });
  const md = useMediaQuery({ maxWidth: 1024 });

  const [isLoading, setIsLoading] = React.useState(true);
  const [campaigns, setCampaigns] = React.useState(null);

  const getCampaigns = async () => {
    const campaigns = await axiosInstance.get(
      `/customer/ecommerce/products?type=DONATION&page=1
      }&perPage=100`
    );

    setCampaigns(campaigns?.data.data);
    setIsLoading(false);
  };

  useEffect(() => {
    getCampaigns();
  }, []);

  return (
    <Spin spinning={isLoading}>
      <div style={{ padding: xs ? "0px 16px" : "0px 48px", marginBottom: 32 }}>
        <div style={{ marginTop: 24, marginBottom: 30 }}>
          <div className={classes.title}>
            Berkontribusi untuk Proyek Akhirat bersama{" "}
            {process.env.REACT_APP_NAME || "Masjid Baitussalam"}
          </div>
        </div>

        <Grid container spacing={3}>
          {campaigns?.map((data, index) => (
            <Grid key={index} item xs={xs ? 12 : md ? 6 : 4}>
              <CardCampaign data={data} />
            </Grid>
          ))}
        </Grid>
        {campaigns !== null && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: 100,
            }}
          ></div>
        )}
      </div>
    </Spin>
  );
}
export default DonationList;
