import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import DonationCampaign from "./components/donation-card/CampaignDonation";
import { axiosInstance } from "./axios";

const useStyles = makeStyles({
  root: {
    width: "100%",
    maxWidth: 444,
    marginTop: 75,
    marginBottom: 32,
  },
});

function SubmitDonation() {
  const classes = useStyles();

  const handleCalculate = async (price, campaign) => {
    try {
      const res = await axiosInstance.post(
        "customer/ecommerce/orders/calculate?showAllPayment=true",
        {
          ...(campaign?.id === process.env.ZAKAT_ID
            ? {
                items: [
                  {
                    productId: campaign?.id,
                    quantity: 1,
                    customPrice: Number(price),
                  },
                ],
              }
            : {
                item: {
                  productId: campaign?.id,
                  quantity: 1,
                  customPrice: Number(price),
                },
              }),
          contact: {
            phone: "",
            email: null,
          },
          shipping: null,
          billing: {
            name: "test",
            phone: "",
            email: "",
            address: "",
            postcode: "",
            country: "",
            latitude: 0,
            longitude: 0,
            note: "",
            province: "",
            cityId: "",
            city: "",
          },
          voucherCode: "",
          customerNote: "",
          isAnonymousDonor: false,
          metadata: null,
        }
      );
      return res;
    } catch (e) {
      console.log(e.response);
    }
    return null;
  };

  const handleSubmit = async (data) => {
    try {
      const res = await axiosInstance
        .post("customer/ecommerce/orders/guest-checkout", data)
        .then((res) => {
          window.location.href = res.data.data.payments[0].url;
        })
        .catch((err) => {
          console.log(err);
        });
      return res;
    } catch (error) {
      console.log(error.response.message);
    }
    return null;
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
      <div className={classes.root}>
        <DonationCampaign
          handleCalculate={async (price, campaign) =>
            await handleCalculate(price, campaign)
          }
          handleSubmit={async (data) => await handleSubmit(data)}
        />
      </div>
    </div>
  );
}
export default SubmitDonation;
